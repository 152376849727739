<template>
  <div class="flex flex-col w-full list__container">
    <ul class="flex w-full sort">
      <li
        v-for="(item, i) in sort"
        :key="i"
        class="sort__item"
        :data-col="i + 1 === sort.length ? 0 : i + 1"
        :class="{
          'sort__item--active': item.active,
          'justify-center': item.align === 'center',
        }"
      >
        <Button @clicked="emitItem(item.emit || '')">
          <span>{{ item.label }}</span>
          <Icon v-if="icon" class="ml-2" :icon="icon" />
        </Button>
      </li>
    </ul>

    <Tile :border-radius="0" background="none" :box-shadow="null">
      <ul class="flex flex-col grow">
        <slot />
      </ul>
    </Tile>
  </div>
</template>

<script>
import { Icon } from "cavea-design-system";

export default {
  name: "List",

  components: {
    Button: () => import("@/components/Button"),
    Tile: () => import("@/components/Tile"),
    Icon,
  },

  props: {
    sort: {
      type: Array,
      required: true,
    },

    icon: {
      type: String,
      default: null,
    },
  },

  methods: {
    emitItem(emitMsg) {
      if (emitMsg) {
        this.$emit(emitMsg);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/globals";
@import "../styles/colors";

.list__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.list--has-thumbnails {
  .list__content {
    width: calc(100% - 5rem);
  }
}

.list__item {
  height: 5.5rem;
  display: flex;
  align-items: center;
  padding: 0 1.5rem 0 0;
  border-radius: 5px;
  background-color: color(blue, base);
  margin: 0.5rem 0;
}

.list__item-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list__item-img {
  width: 5.5rem;
  height: 5.5rem;
  display: flex;
  color: color(blue, light);
  flex-shrink: 0;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  > img {
    object-fit: cover;
    border-radius: 5px;
    height: 100%;
    width: 100%;
  }
}

.list__item-content {
  display: flex;
  flex-grow: 1;
  width: 0;
}

.sort {
  display: flex;
  width: 100%;
  padding: 0 1.5rem 0 5.5rem;
  margin-bottom: 1rem;
}

.sort__item {
  display: flex;

  .icon {
    display: none;
  }
}

.sort__item--active {
  .icon {
    display: inline-block;
  }

  /* > .button,
    .icon {
        color: color(white, base);
    } */
}
</style>
